export const MenuItems = [
  {
    label: "Home",
    link: "#home",
  },
  {
    label: "About Us",
    link: "#about",
  },
  {
    label: "Services",
    link: "#services",
  },
  {
    label: "Contact Us",
    link: "#contact",
  },
];
